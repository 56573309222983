import { axiosInstance } from "../../axios";

const GETUsers = "api/User/SearchAdminUsers";
const GETCurrentUsers = "api/User/Get";
const GETCaseOfficer = "api/User/SearchCaseOfficers";

const POSTCreateUser = "api/User/CreateNewUser";
const GETCustomers = "api/User/SearchAdminCustomersByPage/";
const POSTCreateCustomer = "api/User/CreateNewCustomer";
const POSTRemoveUser = "api/User/Delete?id=";



const getCurrentUser = async function() {
  try {
    const response = await axiosInstance.bhAPIInstance.get(GETCurrentUsers);
    return parseList(response);
  } catch (error) {
    return null;
  }
};


const getCaseOfficer = async function() {
  try {
    const response = await axiosInstance.bhAPIInstance.get(GETCaseOfficer);
    return parseList(response);
  } catch (error) {
    return null;
  }
};



const searchUser = async function() {
  try {
    const response = await axiosInstance.bhAPIInstance.get(GETUsers);
    return parseList(response);
  } catch (error) {
    return null;
  }
};

const createUser = async function(user) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTCreateUser, user);
    return response.data;
  } catch (error) {
    return null;
  }
};

const removeUser = async function(usercompanyid) {
  try {
    const response = await axiosInstance.bhAPIInstance.delete(POSTRemoveUser + usercompanyid);
    return response.data;
  } catch (error) {
    return null;
  }
};

const searchCustomer = async function(search, page) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(
      `${GETCustomers + page}/search/${search}`);
    return parseList(response);
  } catch (error) {
    return null;
  }
};

const createCustomer = async function(user) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTCreateCustomer, user);
    return response.data;
  } catch (error) {
    return null;
  }
};

const parseList = response => {
  if (response.status !== 200) throw Error(response.message);
  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};

export const userData = {
  searchUser,
  createUser,
  searchCustomer,
  createCustomer,
  removeUser,
  getCurrentUser,
  getCaseOfficer
};
