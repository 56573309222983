<template>
<vs-row vs-type="flex" vs-justify="center">
    <div class="flex flex-wrap">
        <div class="btn-group mb-base">
            <vs-button size="small" type="border" color="dark" @click="handlePrevious">Previous</vs-button>
            <vs-button size="small" type="border" color="dark" v-for="(page, i) in pages" @click="handleSelected(page)" :key="i">{{ page }}</vs-button>
            <vs-button size="small" type="border" color="dark" @click="handleNext">Next</vs-button>
        </div>
    </div>
</vs-row>
</template>

<script>
export default {
    data() {
        return {
            alphabets: [
                "All",
                "123",
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
            ],
            selected: "All",
            currentPage: 1,
        };
    },
    computed: {
        pages() {
            if (!this.isNumeric) {
                return this.alphabets.filter((p) => p !== "123");
            }
            return this.alphabets;
        },
    },
    props: {
        isNumeric: {
            type: Boolean,
            default: () => true,
        },
    },
    methods: {
        handleSelected(value) {
            this.selected = value;
            this.currentPage = 1;
            this.$emit("searchByPage", this.currentPage, this.selected);
        },
        handlePrevious() {
            this.currentPage -= 1;
            if (this.currentPage <= 0) {
                this.currentPage = 1;
            }
            this.$emit("searchByPage", this.currentPage, this.selected);
        },

        handleNext() {
            this.currentPage += 1;
            this.$emit("searchByPage", this.currentPage, this.selected);
        },
    },
};
</script>
