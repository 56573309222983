<template>
<vx-card title="Customers" :subtitle="`Total: ${customers.length}`">
    <div slot="actions" class="flex justify-center">
        <vs-select name="CaseOfficerId" placeholder="Select Case Officer" v-model="selectedCaseifficer" @change="filterData">
            <vs-select-item :key="index" :value="item.id" :text="item.displayName" v-for="(item, index) in caseOfficers" />
        </vs-select>
        <vs-input v-model="searchString" class="ml-2" @keyup="handleFilter" placeholder="Search"></vs-input>
        <vs-button class="ml-2" @click="addCustomer">Add</vs-button>
    </div>
    <buttonComponentVue @searchByPage="searchByPage" :isNumeric="false"></buttonComponentVue>
    <vs-table @change-page="handleChangePage" :sst="true" stripe :data="customers" v-if="customers.length">
        <template #thead>
            <vs-th>Name</vs-th>
            <vs-th>Date Of Birth</vs-th>
            <vs-th>Client Department</vs-th>
            <vs-th>Clearance Type</vs-th>
            <vs-th>Clearance Status</vs-th>
            <vs-th>Case Officer</vs-th>
        </template>
        <template>
            <vs-tr :key="i" v-for="(tr, i) in customers" :data="tr">
                <vs-td>
                    <a href="javascript:;" @click="detailsSelected(tr.id)">{{ tr.displayName }}</a>
                </vs-td>
                <vs-td>
                    {{ tr.dateofBirthString }}
                </vs-td>
                <vs-td>
                    {{ tr.clientDepartment }}
                </vs-td>
                <vs-td>
                    {{ tr.clearanceType }}
                </vs-td>
                <vs-td>
                    {{ tr.clearanceStatus }}
                </vs-td>
                <vs-td>
                    {{ tr.caseOfficer }}
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData>
</vx-card>
</template>

<script>
import {
    customerData
} from '../../store/api/customer';
import {
    userData
} from "../../store/api/user";

import _ from 'lodash';
import buttonComponentVue from '../../components/buttonComponent.vue';

export default {
    data() {
        return {
            customers: [],
            caseOfficers: [],
            searchString: "",
            selectedAlphabet: "",
            noData: {
                title: "No Records Found",
            },
            intervalId: null,
            activePage: 1,
            selectedCaseifficer: "",
            currentPage: 1,
            selectedAlphabet: "All",
        }
    },
    components: {
        buttonComponentVue,
    },
    async created() {
        await this.loadCaseOfficers();
        await this.loadCurrentCaseOfficers();
        if (this.selectedCaseifficer == "")
            await this.handleFilter();

    },
    methods: {
        async searchByPage(page, alphabet) {
            this.currentPage = page;
            this.selectedAlphabet = alphabet;
            this.loadCustomers();
        },
        async filterData() {
            await this.handleFilter();
        },
        async loadCurrentCaseOfficers() {
            let result = await userData.getCurrentUser();
            if (result.userType === "CaseOfficer")
                this.selectedCaseifficer = result.id;
        },
        async loadCaseOfficers() {
            this.caseOfficers = [];
            this.caseOfficers = await userData.getCaseOfficer();
        },
        async loadCustomers() {
            this.customers = [];
            this.customers = await customerData.getAllCustomer();
        },
        async handleFilter() {
            this.activePage = 1;
            if (this.intervalId) {
                clearTimeout(this.intervalId)
            }
            this.intervalId = setTimeout(async () => {
                if (this.searchString) this.selectedAlphabet = "All";

                var payload = {
                    Search: this.searchString,
                    caseOfficerId: this.selectedCaseifficer
                }
                this.customers = await customerData.getCustomerByPage(payload);
            }, 200);
        },
        handleSort(key, active) {
            this.customers = _.orderBy(this.customers, key, active);
        },
        detailsSelected(id) {
            this.$router.push(`/admin/Customers/detail/${id}`);
        },
        async handleChangePage(page) {
            this.activePage = page
            await this.loadCustomers()
        },
        async addCustomer() {
            this.$router.push("/admin/customers/add");
        },
    }
}
</script>
