import { axiosInstance } from "../../axios";

const GETAllCustomer = "api/Customer/GetAll";
const GETCustomerById = "api/Customer/GetByKey/";
const GETCustomerViewById = "api/Customer/GetData/";

const GETAllTitle = "api/DropdownList/GetAllTitle";
const GETAllLevel = "api/DropdownList/GetAllLevel";
const GETAllClearanceStatus = "api/DropdownList/GetAllClearanceStatus";
const GETAllCommentsByCustomerId = "api/Comment/GetByCustomerKey/";
const GETCustomerByPage = "api/Customer/GetCustomer";

const POSTCustomer = "api/Customer/Create";

const PUTArchiveCustomer = "api/Customer/Activate";
const PUTActiveCustomer = "api/Customer/Deactivate";
const PUTDeactiveCustomer = "api/Customer/Remove";








const getCustomerById = async function (id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETCustomerById +id);
        return parseList(response);
    } catch (error) {
        return null;
    }
};
const getCustomerViewById = async function (id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETCustomerViewById +id);
        return parseList(response);
    } catch (error) {
        return null;
    }
};




const getCustomerByPage = async function(payload) {
    try {

        // caseofficeid = caseofficeid == "" ? "00000000-0000-0000-0000-000000000000" : caseofficeid
        // let apiUrl = `${GETCustomerByPage}/${caseofficeid}/${search}`;
        // apiUrl =apiUrl.replace(/\/\//g, "/");
      //const response = await axiosInstance.bhAPIInstance.get(apiUrl);
      const response = await axiosInstance.bhAPIInstance.post(GETCustomerByPage, payload);
      //return response.data;
      return parseList(response);
    } catch (error) {
      return [];
    }
  };


const getAllCustomer = async function () {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAllCustomer);
        return parseList(response);
    } catch (error) {
        return null;
    }
};


const removeCustomer = async function (id) {
    try {
        const response = await axiosInstance.bhAPIInstance.delete(PUTArchiveCustomer, { id: id });
        return parseList(response);
    } catch (error) {
        return null;
    }
};

const createCustomer = async function (Customer) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTCustomer, Customer);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const activeCustomer = async function (id) {
    try {
        const response = await axiosInstance.coreAPIInstance.put(
            PUTActiveCustomer,
            { id: id }
        );
        return response.data;
    }
    catch (eror) {
        return null;
    }

};


const inactiveCustomer = async function (id) {
    try {
        const response = await axiosInstance.coreAPIInstance.put(
            PUTDeactiveCustomer,
            { id: id }
        );
        return response.data;
    }
    catch (eror) {
        return null;
    }

};


const geAllTitle = async function () {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAllTitle);
        return parseList(response);
    } catch (error) {
        return null;
    }
};

const getAllLevel = async function () {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAllLevel);
        return parseList(response);
    } catch (error) {
        return null;
    }
};

const getAllClearanceStatus = async function () {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAllClearanceStatus);
        return parseList(response);
    } catch (error) {
        return null;
    }
};

const getCommentByCustomerId = async function (id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAllCommentsByCustomerId +id);
        return parseList(response);
    } catch (error) {
        return null;
    }
};




const parseList = response => {
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
        list = [];
    }
    return list;
};

export const customerData = {
    getCustomerById,
    getAllCustomer,
    removeCustomer,
    createCustomer,
    activeCustomer,
    inactiveCustomer,
    getAllClearanceStatus,
    getAllLevel,
    geAllTitle,
    getCommentByCustomerId,
    getCustomerByPage,
    getCustomerViewById
}

